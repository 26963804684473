var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "vx-card",
        { staticClass: "mb-4" },
        [
          _c("form-detail-include", {
            attrs: { model: _vm.model.appeal, appeal_handling_id: _vm.id },
          }),
          _c(
            "div",
            { staticClass: "w-full flex gap-2 justify-end print:hidden" },
            [
              _c(
                "vs-button",
                {
                  directives: [
                    {
                      name: "permission",
                      rawName: "v-permission",
                      value: "appeal_handlings.edit",
                      expression: "'appeal_handlings.edit'",
                    },
                  ],
                  staticClass: "float-right",
                  attrs: { color: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.handling()
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("action.handling")))]
              ),
              _c(
                "vs-button",
                {
                  staticClass: "float-right",
                  attrs: { color: "primary", type: "border" },
                  on: { click: _vm.goback },
                },
                [_vm._v(_vm._s(_vm.$t("action.back")))]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div>
    <vx-card class="mb-4">
      <form-detail-include :model="model.appeal" :appeal_handling_id="id"></form-detail-include>
      <div class="w-full flex gap-2 justify-end print:hidden">
         <vs-button
            v-permission="'appeal_handlings.edit'"
            class="float-right"
            color="primary"
            @click="handling()">{{ $t('action.handling') }}</vs-button>
          <vs-button
            class="float-right"
            color="primary"
            type="border"
            @click="goback">{{ $t('action.back') }}</vs-button>
      </div>
    </vx-card>
  </div>
</template>

<script>

import standard from '@/services/standard'
import { isAdmin } from '@/util/Util'

import FormDetailInclude from '../FormDetailInclude.vue'

import AppealHandlingService from '@/services/api/AppealHandlingService'


export default {
  directives: {

  },
  components: {
    FormDetailInclude
  },
  props: {
    id: {
      default: null,
      type: Number
    }
  },

  data: () => ({
    service: null,
    questionnaire: null,
    model: {
      id: null,
    }
  }),
  computed: {

  },
  beforeMount() {
    this.service = AppealHandlingService.build(this.$vs)
  },
  mounted() {
    if (this.id) {
      this.loadData(this.id)
    }
  },
  methods: {
    loadData(id) {
      this.$vs.loading()

      this.service.read(id).then(
        response => {
          this.model = response
        },
        error => {this.$vs.loading.close()}
      ).finally(() => {this.$vs.loading.close()})
    },
    handling() {
      this.$router.push(`/appeal_handlings/${this.id}/handling`)
    },
    goback() {
      this.$router.push('/appeal_handlings')
    },
    isAdmin() {
      return isAdmin()
    },
  }
}
</script>

<style>

</style>

